export enum Labels {
  NETWORK_NAME = 'Network name',
  NETWORK_TYPE = 'Network type',
  BESU_IMAGE = 'Besu image',
  BESU_IMAGE_SECRET = 'Besu image secret',
  CONSENSUS = 'Consensus protocol',
  VALIDATORS_NUM = 'Number of validators',
  BOOTNODES_NUM = 'Number of bootnodes',
  RPC_NODES_NUM = 'Number of RPC nodes',
  RPC_AUTH_STRATEGY = 'Type of authentication',
  RPC_CONFIG_URL = 'RPC configuration URL',
  RPC_ENDPOINT = 'RPC endpoint',
  RPC_WS_ENDPOINT = 'RPC WS endpoint',
  REQUESTED_CPU = 'Requested CPU',
  CPU_LIMIT = 'CPU limit',
  REQUESTED_MEMORY = 'Requested memory',
  MEMORY_LIMIT = 'Memory limit',
  STORAGE = 'Storage',
  BLOCK_PERIOD = 'Block period (seconds)',
  GAS_FREE_NETWORK = 'Gas-free network',
  VALIDATORS_AVAILABLE = 'Validators available',
  BOOTNODES_AVAILABLE = 'Bootnodes available',
  RPC_NODES_AVAILABLE = 'RPC nodes available',
  CHAIN_ID = 'Chain ID',
  NODE_NAME = 'Node name',
  NODE_TYPE = 'Node type',
  ADDRESS = 'Address',
  USERNAME = 'Username',
  PASSWORD = 'Password',
  VOTING_TYPE = 'Voting type',
  ACCOUNT_ADDRESS = 'Account address',
  UPDATE_CONFIGURATION_FILE = 'Upload configuration file',
  ENTER_MANUALLY = 'Enter manually',
  GENESIS_FILE = 'Genesis block file',
}
