import { Text, TextInput } from '@intellecteu/common-ui';
import { Labels, minNodesNum } from 'app/constants';
import { handleIntegerInput } from 'app/utils';
import { Field, Resources } from 'app/components/core';
import { StepProps } from 'app/components/networks/types';

import s from './styles.module.scss';

export const ResourcesStep = ({ form: { values, errors, setFieldTouched } }: StepProps) => (
  <>
    <Text className={s.formSidebarSectionTitle} weight="bold" size="s">
      Validator resources
    </Text>

    <Resources startName="validatorResources" errors={errors} setFieldTouched={setFieldTouched}>
      <Field
        component={TextInput}
        id="numOfNodesDto.validatorsNum"
        name="numOfNodesDto.validatorsNum"
        label={`${Labels.VALIDATORS_NUM}*`}
        placeholder={`Min ${minNodesNum[values.consensusMechanism].validatorsNum}`}
        onKeyPress={handleIntegerInput}
      />
    </Resources>

    <Text className={s.formSidebarSectionTitle} weight="bold" size="s">
      Bootnode resources
    </Text>

    <Resources startName="bootNodeResources" errors={errors} setFieldTouched={setFieldTouched} isBoot>
      <Field
        component={TextInput}
        id="numOfNodesDto.bootnodesNum"
        name="numOfNodesDto.bootnodesNum"
        label={`${Labels.BOOTNODES_NUM}*`}
        placeholder={`Min ${minNodesNum[values.consensusMechanism].bootnodesNum}`}
        onKeyPress={handleIntegerInput}
      />
    </Resources>

    <Text className={s.formSidebarSectionTitle} weight="bold" size="s">
      RPC node resources
    </Text>

    <Resources startName="rpcNodeResources" errors={errors} setFieldTouched={setFieldTouched}>
      <Field
        component={TextInput}
        id="numOfNodesDto.rpcNodesNum"
        name="numOfNodesDto.rpcNodesNum"
        label={`${Labels.RPC_NODES_NUM}*`}
        placeholder={`Min ${minNodesNum[values.consensusMechanism].rpcNodesNum}`}
        onKeyPress={handleIntegerInput}
      />
    </Resources>
  </>
);

export { type ResourcesStepSchema, ResourcesSchemaValidation } from './ResourcesStepSchema';
